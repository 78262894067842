import type { mainStore } from "@/mainStore";
export async function getXrsfToken(context: mainStore) {
    let xrsfToken = context.xrsfToken;
    if (!xrsfToken) {
        xrsfToken = await refreshXsrfToken(context);
    }
    return xrsfToken;
}

export function refreshXsrfToken(context: mainStore): Promise<string> {
    if (context.xrsfTokenPromise == null) {
        context.xrsfTokenPromise = (async () => {
            const url = await context.urlFetcher("getUserData");
            const response = await fetch(url, { cache: "reload" });
            const json = await response.json();
            const xrsfToken = json.XrsfToken;
            context.setXrsfToken(xrsfToken);
            return xrsfToken;
        })();
    }
    return context.xrsfTokenPromise;
}

export async function getXsftHeader(context: mainStore) {
    return {
        "X-XSRF-TOKEN": await getXrsfToken(context),
    };
}
